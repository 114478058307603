import cloneDeep from 'lodash/cloneDeep'
import stringify from 'qs/lib/stringify'
import { showError, getUserIDFromChannel } from '~/utils'
import { getCache, deleteAllCache } from '../../utils/cache'
import AgoraService from '../../utils/agora'

export default {
  initializeChat({ commit, dispatch, state }) {
    dispatch('getChatCache')
    // eslint-disable-next-line no-underscore-dangle
    const getStatus = this.$centrifuge._status
    commit('setDisconnect', getStatus === 'disconnected')
    if (getStatus === 'disconnected') {
      let token = this.$auth.strategy.token.get() || ''
      if (token.includes('Bearer')) {
        // eslint-disable-next-line prefer-destructuring
        token = token.split('Bearer ')[1]
      }
      this.$centrifuge.setToken(token)

      this.$centrifuge.connect()

      const getCurrentUserId = this.$auth.user.id
      let subscription = null
      const vm = this

      const onConnect = function _() {
        commit('setDisconnect', false)
        if (!subscription) {
          subscription = this.subscribe(`#${getCurrentUserId}`, async message => {
            const { data } = message
            if (state.activeChat && state.activeChat.channelID === data.channelID) {
              commit('addActiveChatList', data)
            }
            if (
              data.content.startsWith('/orders')
              && ((state.activeChat && state.activeChat.user && state.activeChat.user.id === data.userID) || data.userID === getCurrentUserId)
            ) {
              const status = data.content.split(' ')[2]
              if (['accepted', 'completed', 'cancelled'].includes(status)) {
                dispatch('getActiveOrder')
              }
            }
            if (data.content.startsWith('/calling')) {
              const status = data.content.split(' ')[2]
              if (status === 'calling') {
                const getOrderId = data.content.split(' ')[1]
                const { data: order } = await vm.$apiV2.get(`/payment-order/orders/${getOrderId}`)

                const user = order.order_snapshot.buyer_user.nickname === getCurrentUserId ? order.order_snapshot.buyer_user : order.order_snapshot.seller_user
                const callData = cloneDeep(data)
                callData.user = user
                callData.order = order
                commit('setCall', callData)
                if (data.userID !== getCurrentUserId) {
                  commit('setIncomingCallVisible', true)
                }
              }
              if (status === 'end') {
                commit('setCallStarted', false)
                commit('setCall', null)
                commit('setIncomingCallVisible', false)
                const agoraService = new AgoraService()
                agoraService.leave()
              }
            }
            dispatch('updateChatList', data)
          })
        }

        dispatch('getChatList')
      }

      this.$centrifuge.on('connect', onConnect)

      this.$centrifuge.on('disconnect', () => {
        if (subscription) {
          subscription.unsubscribe()
        }
        commit('setDisconnect', true)
      })

      this.$axios
        .get('orders/me', {
          params: {
            isCalling: true,
            orderBy: 'updatedAt desc',
          },
        })
        .then(response => {
          const {
            data: { data },
          } = response

          if (data.length > 0) {
            const order = data[0]
            const user = order.userID === getCurrentUserId ? order.coachData : order.userData
            const callData = cloneDeep(data)
            callData.user = user
            callData.order = order
            commit('setCall', callData)
          }
        })
    }
  },
  async getChatList({ commit, state }) {
    try {
      const {
        data: { data },
      } = await this.$axios.get('chats/subscriptions')

      if (state.chatList.length > 0 && data.length > 0) {
        const chats = cloneDeep(state.chatList)
        data.forEach(element => {
          const findExists = chats.findIndex(x => x.channelID === element.channelID)
          if (findExists === -1) {
            chats.push(element)
          } else {
            chats[findExists].unreadEventCount = element.unreadEventCount
            chats[findExists].lastMessage = element.lastMessage
            chats[findExists].updatedAt = element.updatedAt
          }
        })
        commit('setChatList', chats)
      } else {
        commit('setChatList', data)
      }
    } catch (error) { }
  },
  async checkBlacklist({ commit }, { peerId, mineId }) {
    const {
      data: { data: blacklist },
    } = await this.$axios.get(`users/blacklists/check?userID=${peerId}&checkingUserID=${mineId}`)
    commit('setBlacklist', blacklist.isBlacklist)
  },
  async getChatCache({ commit }) {
    if ('caches' in window) {
      const getCurrentUserId = this.$auth.user.id
      const chats = await getCache('chats', `/${getCurrentUserId}.json`)
      if (chats) {
        commit('setChatList', chats)
      }
    }
  },
  async updateChatList({ commit, state }, message) {
    let chatList = cloneDeep(state.chatList)
    if (chatList && chatList.length > 0) {
      const indexOf = chatList.findIndex(x => x.channelID === message.channelID)
      if (indexOf !== -1) {
        chatList[indexOf].lastMessage = message
        if (
          (state.activeChat && state.activeChat.channelID !== message.channelID)
          || !state.activeChat
        ) {
          chatList[indexOf].unreadEventCount += 1
        }
        if (state.activeChat && state.activeChat.channelID === message.channelID) {
          try {
            await this.$centrifuge.rpc({
              method: 'updateReadEvent',
              data: {
                eventID: message.id,
                channelID: message.channelID,
                readEventSeq: message.seq,
              },
            })
          } catch (error) { }
        }
      } else {
        const {
          data: { data: chats },
        } = await this.$axios.get('chats/subscriptions', {
          params: {
            channelID: message.channelID,
          },
        })

        if (chats && chats.length > 0) {
          chatList = chats.concat(chatList)
        }
      }

      commit('setChatList', chatList)
    }
  },
  async getEmojiData({ commit, state }) {
    if (state.emojiSet.length === 0) {
      const {
        data: { data: emoji },
      } = await this.$axios.get('chats/emoji-sets')
      commit('setEmojiSet', emoji)
    }
  },
  async getCacheActiveChatList({ commit, state, dispatch }) {
    commit('clearActiveChatList')
    if ('caches' in window) {
      const getCurrentUserId = this.$auth.user.id
      const otherUserId = getUserIDFromChannel(state.activeChat?.channelName, getCurrentUserId)
      const getActiveChatList = await getCache('chats', `${getCurrentUserId}/chat/${otherUserId}.json`)
      if (getActiveChatList) {
        commit('setActiveChatList', getActiveChatList)
      }
    }
    dispatch('getActiveChatList')
  },
  async getActiveChatList({ commit, state }, loadMore = false) {
    if (state.loading) return
    commit('setLoading', true)

    try {
      const chats = cloneDeep(state.activeChatList)
      const lastChats = chats[chats.length - 1]

      let payload = {
        channelID: state.activeChat.channelID,
        limit: 10,
      }
      if (loadMore && lastChats && lastChats.seq) {
        payload = {
          ...payload,
          direction: 'up',
          lastSeq: lastChats.seq,
        }
      }

      let { data } = await this.$centrifuge.rpc({
        method: 'getHistory',
        data: payload,
      })

      if (state.activeChatList && state.activeChatList.length > 0) {
        data.forEach(element => {
          const findExists = chats.findIndex(x => x.id === element.id)
          if (findExists === -1) {
            chats.push(element)
          }
        })
        data = chats
      }

      commit('setActiveChatList', data)
    } catch (error) {
      showError(error)
    }
    commit('setLoading', false)
  },
  async removeChats({ commit }) {
    if ('caches' in window) {
      await deleteAllCache()
    }
    commit('removeChat')
    commit('clearActiveChatList')
  },
  async createChannel({ commit, dispatch, state }, userIds) {
    // eslint-disable-next-line no-underscore-dangle
    const getStatus = this.$centrifuge._status
    if (getStatus === 'disconnected') {
      dispatch('initializeChat')
    }
    try {
      userIds.sort()
      const channelName = `#${userIds.join(',')}`
      const { data } = await this.$centrifuge.rpc({
        method: 'createChannel',
        data: {
          channelName,
        },
      })
      const currentUserId = this.$auth.user.id
      const chat = {
        channelID: data.id,
        channelName: data.name,
        createdAt: data.createdAt,
        updatedAt: data.updatedAt,
        id: data.id,
        userID: currentUserId,
      }
      const userId = getUserIDFromChannel(channelName, currentUserId)
      const {
        data: { data: user },
      } = await this.$axios.get(`/users/${userId}`)
      chat.user = user
      const chatList = cloneDeep(state.chatList)
      chatList.push(chat)
      commit('clearActiveChatList')
      commit('setChatList', chatList)
      commit('setActiveChat', chat)
    } catch (error) {
      showError(error)
    }
  },
  async getActiveOrder({ commit, state }) {
    try {
      const userID = getUserIDFromChannel(state.activeChat?.channelName, this.$auth.user.id)
      const {
        data: { data },
      } = await this.$axios.get(
        `orders/me?${stringify(
          {
            statuses: ['accepted'],
            userID,
          },
          { indices: false },
        )}`,
      )
      if (data && data.length > 0) {
        commit('setActiveOrder', data[0])
      } else {
        commit('setActiveOrder', null)
      }
    } catch (error) { }
  },
}
