import get from 'lodash/get'
import { showError, safeJsonParse, PAYMENT_METHOD_TYPES } from '~/utils'
import { message } from 'ant-design-vue'

export default {
  async getStripeAccount({ commit, rootState }) {
    try {
      const {
        data: { data },
      } = await this.$axios.get(
        `/payments/stripe/accounts/me?countryCode=${rootState.settings.countryUser}`,
      )
      commit('setStripeAccount', data)
      const paymentMethodType = localStorage.getItem('defaultPaymentMethodType') || PAYMENT_METHOD_TYPES.CARD
      commit('setDefaultPaymentMethod', {
        paymentMethodId:
          paymentMethodType === PAYMENT_METHOD_TYPES.GRABPAY
          || paymentMethodType === PAYMENT_METHOD_TYPES.IPAYMU
          || paymentMethodType === PAYMENT_METHOD_TYPES.STRIPE_WECHAT_PAY
          || paymentMethodType === PAYMENT_METHOD_TYPES.EARNINGS
          || paymentMethodType === PAYMENT_METHOD_TYPES.GCASH
          || paymentMethodType === PAYMENT_METHOD_TYPES.STRIPE_PAYNOW
          || paymentMethodType === PAYMENT_METHOD_TYPES.DRAGONPAY_GCASH
          || paymentMethodType === PAYMENT_METHOD_TYPES.DRAGONPAY_PAYMAYA
          || paymentMethodType === PAYMENT_METHOD_TYPES.SENANGPAY
          || paymentMethodType === PAYMENT_METHOD_TYPES.XENDIT
          || paymentMethodType === PAYMENT_METHOD_TYPES.ZALOPAY_WALLET
          || paymentMethodType === PAYMENT_METHOD_TYPES.ZALOPAY_VIETQR
          || paymentMethodType === PAYMENT_METHOD_TYPES.ZALOPAY_LOCALBANK
          || paymentMethodType === PAYMENT_METHOD_TYPES.HAPPYPAY
          || paymentMethodType === PAYMENT_METHOD_TYPES.PEPAY_LINEPAY
          || paymentMethodType === PAYMENT_METHOD_TYPES.XENDIT_CREDIT_CARD
          || paymentMethodType === PAYMENT_METHOD_TYPES.XENDIT_INTERNET_BANKING
          || paymentMethodType === PAYMENT_METHOD_TYPES.XENDIT_DANA
          || paymentMethodType === PAYMENT_METHOD_TYPES.XENDIT_OVO
          || paymentMethodType === PAYMENT_METHOD_TYPES.XENDIT_QRIS
          || paymentMethodType === PAYMENT_METHOD_TYPES.SIAMPAY_QR_PROMPTPAY
          || paymentMethodType === PAYMENT_METHOD_TYPES.SIAMPAY_TRUEMONEY
          || paymentMethodType === PAYMENT_METHOD_TYPES.STRIPE_GOOGLE_PLAY
          || paymentMethodType === PAYMENT_METHOD_TYPES.STRIPE_APPLEPAY
          || paymentMethodType === PAYMENT_METHOD_TYPES.ELEMENTPAY
          || paymentMethodType === PAYMENT_METHOD_TYPES.PAYPAL
            ? null
            : data.defaultPaymentMethod,
        type: paymentMethodType,
      })
    } catch (error) {
      showError(error, 'Get stripe account failed!')
    }
  },
  async getStripeEphemeralData({ commit, rootState }) {
    try {
      const {
        data: { data },
      } = await this.$axios.get(
        `/payments/stripe/accounts/me/ephemeral-key?stripeVersion=${this.$config.stripeVersion}&countryCode=${rootState.settings.countryUser}`,
      )
      commit('setEphemeralData', data)
      return data
    } catch (error) {
      showError(error, 'Get stripe ephemeral data failed!')
      return undefined
    }
  },
  async getPaymentMethods({ commit, rootState }) {
    try {
      await this.$axios.get(
        `/payments/stripe/accounts/me?countryCode=${rootState.settings.countryUser}`,
      )
      const {
        data: { data },
      } = await this.$axios.get(
        `/payments/stripe/accounts/me/payment-methods?countryCode=${rootState.settings.countryUser}`,
      )
      commit('setPaymentMethods', data)
    } catch (error) {
      showError(error, 'Get payment methods failed!')
    }
  },
  async createNewPaymentMethod({ dispatch, rootState }, paymentMethodID) {
    try {
      await this.$axios.post('payments/stripe/accounts/me/payment-methods', {
        paymentMethodID,
        countryCode: rootState.settings.countryUser,
      })
      await dispatch('getPaymentMethods')
      message.success('Payment Method created!')
      return null
    } catch (error) {
      const err = get(error, 'response.data.errors.message')
      const fallbackMessage = 'Create payment method failed!'
      const parsedError = safeJsonParse(err)
      return get(parsedError, 'message') || fallbackMessage
    }
  },
  async updateDefaultPaymentMethod({ commit, rootState }, { paymentMethodId, type }) {
    try {
      await this.$axios.patch('payments/stripe/accounts/me', {
        defaultPaymentMethod: paymentMethodId,
        countryCode: rootState.settings.countryUser,
      })
      commit('setDefaultPaymentMethod', {
        paymentMethodId,
        type,
      })
    } catch (error) {
      showError(error, 'Update default payment method failed!')
    }
  },

  getFirebasePaymentConfig({ commit }) {
    // * prevent remote config error by try to open indexedDB
    // * remote config will error if users open web using firefox with incognito mode
    const checkIndexedDB = window.indexedDB.open('checking')
    checkIndexedDB.onsuccess = async () => {
      try {
        await this.$fire.remoteConfigReady()
        await this.$fire.remoteConfig.fetchAndActivate()
        const getConfig = this.$fire.remoteConfig.getValue(
          process.env.NUXT_ENV_TYPE !== 'production' ? 'paymentStaging' : 'paymentProduction',
        )
        // eslint-disable-next-line no-underscore-dangle
        commit('setPaymentConfig', JSON.parse(getConfig._value))
      } catch (error) {
        showError(error)
      }
    }

    checkIndexedDB.onerror = () => {
      showError(
        null,
        'Gank Payment requires cookies to be enabled. Please try again without private browsing.',
      )
    }
  },
  getFirebaseWithdrawalConfig({ commit }) {
    // * prevent remote config error by try to open indexedDB
    // * remote config will error if users open web using firefox with incognito mode
    const checkIndexedDB = window.indexedDB.open('checking')
    checkIndexedDB.onsuccess = async () => {
      try {
        await this.$fire.remoteConfigReady()
        await this.$fire.remoteConfig.fetchAndActivate()
        const getConfig = this.$fire.remoteConfig.getValue(
          process.env.NUXT_ENV_TYPE !== 'production' ? 'withdrawalStaging' : 'withdrawalProduction',
        )
        // eslint-disable-next-line no-underscore-dangle
        commit('setWithdrawalConfig', JSON.parse(getConfig._value))
      } catch (error) {
        showError(error)
      }
    }

    checkIndexedDB.onerror = () => {
      showError(
        null,
        'Gank Payment requires cookies to be enabled. Please try again without private browsing.',
      )
    }
  },
}
