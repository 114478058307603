import orderBy from 'lodash/orderBy'
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import { getUserIDFromChannel } from '~/utils'
import { addCache } from '~/utils/cache'

export default {
  setLoading(state, loading) {
    state.loading = loading
  },
  setDisconnect(state, data) {
    state.isDisconnected = data
  },
  setPrevSubs(state, subs) {
    state.prevSubs = subs
  },
  setSubs(state, subs) {
    state.subs = subs
  },
  clearChatData(state) {
    state.subs = []
    state.prevSubs = []
    state.position = 0
    state.selectedChat = null
  },
  setLastMessage(state, message) {
    const nextSubs = state.subs.reduce((prev, cur) => {
      if (cur.channelID === message.channelID) {
        return [
          ...prev,
          {
            ...cur,
            lastMessage: {
              ...message,
            },
          },
        ]
      }
      return [...prev, cur]
    }, [])
    const updatePrevSubs = state.prevSubs.reduce((prev, cur) => {
      if (cur.channelID === message.channelID) {
        return [
          ...prev,
          {
            ...cur,
            lastMessage: {
              ...message,
            },
          },
        ]
      }
      return [...prev, cur]
    }, [])
    state.prevSubs = orderBy(updatePrevSubs, ['lastMessage.createdAt'], ['desc'])
    state.lastMessage = message
    state.subs = orderBy(nextSubs, ['lastMessage.createdAt'], ['desc'])
  },
  setPosition(state, data) {
    state.position = data
  },
  setSelectedChat(state, user) {
    state.selectedChat = user
  },
  setSelectedOrder(state, data) {
    if (data) {
      const buyer = state.subs.find(item => item.user.id === data.userID)
      const seller = state.subs.find(item => item.user.id === data.coachID)
      state.selectedOrder = { ...data, buyer: get(buyer, 'user') || {} }
      state.selectedOrder = { ...data, seller: get(seller, 'user') || {} }
    } else {
      state.selectedOrder = null
    }
  },
  setLoadingOrder(state, loading) {
    state.loadingOrder = loading
  },
  setCurrentSession(state, data) {
    const prevSessionState = get(state, 'currentSession') || {}
    state.currentSession = {
      ...prevSessionState,
      ...data,
    }
  },
  setPeerAudioMuted(state, value) {
    state.peerAudioMuted = value
  },
  setTransferBarVisible(state, value) {
    state.transferBarVisible = value
  },
  setPeerJoined(state, value) {
    state.peerJoined = value
  },
  setPeerEndSession(state, value) {
    state.peerEndSession = value
  },
  setPeerSharedScreen(state, value) {
    state.peerSharedScreen = value
  },
  setPeerAvatar(state, value) {
    state.peerAvatar = value
  },
  setPeerNickname(state, value) {
    state.peerNickname = value
  },
  setPeerOnlineStatus(state, value) {
    state.peerOnlineStatus = value
  },
  setEmojiSet(state, value) {
    state.emojiSet = value
  },
  toggleOrderSidebar(state) {
    state.orderSidebarCollapsed = !state.orderSidebarCollapsed
  },
  setIncomingCallVisible(state, value) {
    state.incomingCallVisible = value
    state.callType = value ? 'incoming' : ''
  },
  setOutgoingCallVisible(state, value) {
    state.outgoingCallVisible = value
    state.callType = value ? 'outgoing' : ''
  },
  setOutgoingCallMinimized(state, value) {
    state.outgoingCallMinimized = value
  },
  setIncomingCallMinimized(state, value) {
    state.incomingCallMinimized = value
  },
  setIncomingCallOrderID(state, value) {
    state.incomingCallOrderID = value
  },
  setAgoraData(state, value) {
    state.agoraData = value
  },
  setBlacklist(state, value) {
    state.blacklist = value
  },
  setActiveChat(state, value) {
    state.activeChat = value
  },
  async setChatList(state, value) {
    const filterValue = value.filter(item => item.lastMessage !== null)
    state.chatList = orderBy(filterValue, ['lastMessage.createdAt'], ['desc'])
    if ('caches' in window) {
      const getCurrentUserId = this.$auth.user.id
      await addCache('chats', `/${getCurrentUserId}.json`, state.chatList)
    }
  },
  setChatListWithoutCache(state, value) {
    const filterValue = value.filter(item => item.lastMessage !== null)
    state.chatList = orderBy(filterValue, ['lastMessage.createdAt'], ['desc'])
  },
  async updateChatUser(state, value) {
    // * value variable object
    // value = chat object
    const findIndex = state.chatList.findIndex(val => val.channelName.includes(value.id))
    const chat = cloneDeep(state.chatList)
    chat[findIndex] = {
      ...chat[findIndex],
      user: value,
    }
    state.chatList = chat

    if ('caches' in window) {
      const getCurrentUserId = this.$auth.user.id
      await addCache('chats', `/${getCurrentUserId}.json`, state.chatList)
    }
  },
  async updateChat(state, value) {
    state.chatList[value.index] = value.data

    if ('caches' in window) {
      const getCurrentUserId = this.$auth.user.id
      await addCache('chats', `/${getCurrentUserId}.json`, state.chatList)
    }
  },
  async updateChatCount(state, value) {
    // * value variable object
    // value = {
    //   id: chatID,
    //   count: number,
    // }

    const findIndex = state.chatList.findIndex(x => x.id === value.id)
    if (findIndex !== -1) {
      const chat = cloneDeep(state.chatList)
      chat[findIndex].unreadEventCount = value.count
      state.chatList = chat
    }

    if ('caches' in window) {
      const getCurrentUserId = this.$auth.user.id
      await addCache('chats', `/${getCurrentUserId}.json`, state.chatList)
    }
  },
  deleteChat(state, value) {
    state.chatList.splice(value, 1)
  },
  removeChat(state) {
    state.chatList = []
    state.activeChat = null
  },
  clearActiveChatList(state) {
    state.activeChatList = []
  },
  async clearActiveChatListCache(state) {
    if (!('caches' in window)) {
      return
    }

    const getCurrentUserId = this.$auth.user.id
    const otherUserId = getUserIDFromChannel(state?.activeChat?.channelName, getCurrentUserId)
    await addCache('chats', `${getCurrentUserId}/chat/${otherUserId}.json`, [])
  },
  async setActiveChatList(state, value) {
    value.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    state.activeChatList = value
    if ('caches' in window) {
      const getCurrentUserId = this.$auth.user.id
      const otherUserId = getUserIDFromChannel(state?.activeChat?.channelName, getCurrentUserId)
      await addCache('chats', `${getCurrentUserId}/chat/${otherUserId}.json`, state.activeChatList)
    }
  },
  async addActiveChatList(state, value) {
    state.activeChatList = [value].concat(state.activeChatList)
    if ('caches' in window) {
      const getCurrentUserId = this.$auth.user.id
      const otherUserId = getUserIDFromChannel(state?.activeChat?.channelName, getCurrentUserId)
      await addCache('chats', `${getCurrentUserId}/chat/${otherUserId}.json`, state.activeChatList)
    }
  },
  setCall(state, value) {
    state.call = value
  },
  setCallStarted(state, value) {
    state.callStarted = value
  },
  setActiveOrder(state, value) {
    state.activeOrder = value
  },
}
