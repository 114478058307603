export default {
  setStripeAccount(state, data) {
    state.stripeAccount = data
  },
  setPaymentMethods(state, data) {
    state.paymentMethods = data
  },
  setDefaultPaymentMethod(state, data) {
    state.defaultPaymentMethodId = data.paymentMethodId
    state.defaultPaymentMethodType = data.type
  },
  setEphemeralData(state, data) {
    state.ephemeralData = data
  },
  setPaymentConfig(state, data) {
    state.paymentConfig = data
  },
  setWithdrawalConfig(state, data) {
    state.withdrawalConfig = data
  },
  setTransactionToCheck(state, data) {
    state.transactionToCheck = data
    state.loading = !!data
  },
  setCheckStatusInterval(state, data) {
    state.checkStatusInterval = data
  },
  setLoading(state, value) {
    state.loading = value
  },
  setPaymentLoading(state, value) {
    state.paymentLoading = value
  },
  setPromptpayData(state, value) {
    state.promptpayData = value
  },
}
