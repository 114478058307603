export const initialSessionState = {
  orderId: '',
  agToken: null,
  agChannel: null,
  agUid: null,
  peerUserId: null,
  readyState: false,
  hasCall: false,
}

export default () => ({
  loading: false,
  emojiSet: [],
  subs: [],
  prevSubs: [],
  position: 0,
  selectedChat: null,
  selectedOrder: null,
  currentSession: {
    ...initialSessionState,
  },
  peerJoined: false,
  peerAudioMuted: false,
  peerSharedScreen: false,
  peerEndSession: false,
  peerNickname: '',
  peerAvatar: null,
  peerOnlineStatus: false,
  loadingOrder: false,
  lastMessage: null,
  transferBarVisible: false,
  orderSidebarCollapsed: true,
  incomingCallVisible: false,
  outgoingCallVisible: false,
  incomingCallMinimized: false,
  outgoingCallMinimized: false,
  callType: '', // incoming | outgoing
  agoraData: null,
  incomingCallOrderID: '',
  blacklist: false,
  activeChat: null,
  chatList: [],
  activeChatList: [],
  call: null,
  callStarted: false,
  activeOrder: null,
  isDisconnected: false,
})
