import debounce from 'lodash/debounce'

export default {
  getNotifications: debounce(async function ({ commit }) {
    try {
      const {
        data: { data },
      } = await this.$axios.get('/nms', {
        params: {
          status: 'all',
          page: 1,
          per_page: 20,
        },
      })
      commit('setNotifications', [...data])
    } catch (error) {
      //
    } finally {
      const getOpenedNotif = localStorage.getItem('openedNotifications')
      if (getOpenedNotif) {
        commit('setOpenedNotifications', JSON.parse(getOpenedNotif))
      }
    }
  }, 1000),
}
