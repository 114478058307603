import { cloneDeep } from 'lodash'
import { diffMinutes } from '~/utils'

const parsingAttachments = attachments => {
  const getAttachment = attachments.map(element => element.content.split(' ').slice(1).join('%20'))
  getAttachment.splice(0, 0, '/group')
  const groupAttachment = getAttachment.join(' ')
  return groupAttachment
}

const getters = {
  getUserByID: (state, _getter, rootState) => id => {
    const { user } = rootState.auth
    if (user.id === id) {
      return user
    }
    const sub = state.subs.find(item => item.user.id === id)
    return sub ? sub.user : {}
  },
  getTotalUnreadMessageCount: state => state.chatList.reduce((prevCount, cur) => {
    // check user is deleted or not
    if (cur.isUserPartyDeleted === false) {
      return prevCount + cur.unreadEventCount
    }
    return prevCount
  }, 0),
  getGroupingChatList: state => {
    const chats = cloneDeep(state.activeChatList)
    let attachments = []
    const regexMedia = /^\/video|^\/image/s

    for (let i = 0; i < chats.length; i += 1) {
      const chat = chats[i]
      let getLastAttachment = attachments.length > 0 ? attachments[attachments.length - 1] : null

      if (regexMedia.test(chat.content)) {
        if (attachments.length > 0) {
          const checkSameUserId = getLastAttachment?.userID === chat.userID
          if (checkSameUserId && diffMinutes(getLastAttachment?.createdAt, chat.createdAt) < 30) {
            attachments.push(chat)
          }
        } else {
          attachments.push(chat)
        }
      }

      if (attachments.length > 0 && (!regexMedia.test(chat.content) || diffMinutes(getLastAttachment?.createdAt, chat.createdAt) > 30 || i === chats.length - 1)) {
        if (attachments.length > 3) {
          // update last attachment if i is last index
          if (i === chats.length - 1) {
            getLastAttachment = attachments[attachments.length - 1]
          }
          const groupAttachment = parsingAttachments(attachments)
          const findIndexLastAttachment = chats.findIndex(item => item.id === getLastAttachment.id)
          chats.splice(findIndexLastAttachment, 1, { ...getLastAttachment, content: groupAttachment })
          chats.splice(findIndexLastAttachment - attachments.length + 1, attachments.length - 1)

          i -= attachments.length
        }
        attachments = []
      }
    }

    return chats
  },
}

export default getters
