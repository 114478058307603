import get from 'lodash/get'

export default function axios({
  $axios, app, $sentry, $auth,
}, inject) {
  $axios.onRequest(config => {
    // eslint-disable-next-line no-param-reassign
    config.withCredentials = true
    if (!config.url.includes('media')) {
      return
    }

    const [path] = config.url.split('?').filter(part => part)
    const [firstPath] = path.split('/').filter(part => part)

    if (firstPath !== 'media') {
      return
    }

    // use media api url
    // eslint-disable-next-line no-param-reassign
    config.baseURL = process.env.NUXT_ENV_MEDIA_API_URL
  })

  $axios.onError(error => {
    // do not capture error to sentry for network error
    if (error.code === 'ERR_NETWORK') {
      return
    }

    const responseUrl = get(error, 'request.responseURL', '')
    const isLogoutApiCall = responseUrl.includes('logout')

    const code = parseInt(error.response && error.response.status, 10)
    if (!isLogoutApiCall && code === 401) {
      app.$cookies.remove('auth._token.local')
      app.$auth.logout()
      app.$centrifuge.disconnect()
      app.$store.commit('chat/removeChats')
    }

    // capture api call error
    $sentry.captureException(error)
  })

  function createAPI(baseURL) {
    const api = $axios.create({ baseURL })

    // Setup request interceptor to add Authorization header
    api.onRequest(async config => {
      // Retrieve the auth token and set it in headers
      const authToken = await $auth.strategy.token.get()
      if (authToken) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = authToken
      }

      return config
    })

    return api
  }

  // new v2 domain staging
  const apiV2 = createAPI(process.env.NUXT_ENV_API_V2_URL)

  const v2API = createAPI(`${process.env.NUXT_ENV_API_URL}/v2`)

  // Inject to context as $apiV1 and $apiV2
  inject('apiV2', apiV2)
  inject('v2API', v2API)
}
