
/* eslint-disable */
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import vClickOutside from 'v-click-outside'
import debounce from 'lodash/debounce'
import dayjs from 'dayjs'
import notificationMixin from '~/mixins/notification'

const HeaderMenu = () => import('./HeaderMenuNew.vue')
const LogoWhite = () => import('./LogoWhite.vue')
const LogoRed = () => import('./LogoRed.vue')
const MobileMenuDrawer = () => import('./MobileMenuDrawerNew.vue')
const GankButton = () => import('~/components/Button/GankButton.vue')
const GvButton = () => import('~/components/Button/GvButton.vue')
const PreferenceModal = () => import('~/components/Modal/SitePreference/sitepreference.vue')
const Notifications = () => import('~/components/Notifications/notification.vue')
const Menu = () => import('~/containers/Dashboard/Menu.vue')
const ViewProfile = () => import('~/components/Layouts/ViewProfile.vue')
const defaultUserImage = require('~/assets/images/user.png')

export default {
  components: {
    HeaderMenu,
    LogoWhite,
    LogoRed,
    MobileMenuDrawer,
    GankButton,
    GvButton,
    PreferenceModal,
    Notifications,
    Menu,
    ViewProfile,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mixins: [notificationMixin],
  data() {
    return {
      isMounted: false,
      hover: false,
      keywords: '',
      showPreference: false,
      showNotification: false,
      hasNewNotification: false,
      showProfileDropdown: false,
    }
  },
  computed: {
    ...mapGetters({
      unreadMessageCount: 'chat/getTotalUnreadMessageCount',
    }),
    ...mapState({
      currentSession: state => state.chat.currentSession,
      allCountries: state => state.settings.allCountries,
      countryUser: state => state.settings.countryUser,
      locale: state => state.settings.locale,
      notifications: state => state.notifications.notifications,
      openedNotifications: state => state.notifications.openedNotifications,
    }),
    userAvatar() {
      return this.$auth.user.avatar || defaultUserImage
    },
    showProfileIcon() {
      const list = [
        'chats',
        'discover',
        'boost',
        'creator-grant',
        'nickname',
        'nickname-donate',
        'services-slug',
        'post-id',
        'payment',
      ]

      // return list.includes(this.$route.name)
      return true
    },
    checkNotificationUnread() {
      return this.notifications.length > this.openedNotifications.length
    }
  },
  mounted() {
    this.$nextTick().then(() => {
      this.isMounted = true
      this.setLanguage()
      this.setLocalCookies()
      this.getCountry()
      this.getServiceWorkers()
      this.getNotification()
    })
  },
  methods: {
    ...mapActions({
      getCountries: 'settings/getCountries',
      getNotifications: 'notifications/getNotifications'
    }),
    setLanguage() {
      const isChangeLanguage = this.$auth.$storage.getUniversal('isChangeLanguage')
      if (this.countryUser === 'ID' && !isChangeLanguage) {
        this.$i18n.setLocale('id')
        this.language = 'id'
      } else {
        this.language = this.$i18n.getLocaleCookie()
      }
    },
    setLocalCookies() {
      dayjs.locale(this.languageValue)
      this.$store.commit('settings/setLocale', this.$i18n.getLocaleCookie())
    },
    getCountry() {
      if (this.allCountries.length === 0) {
        this.getCountries()
      }
    },
    getServiceWorkers() {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.addEventListener('message', () => {
          this.hasNewNotification = true
        })
      }
    },
    getNotification() {
      if (this.$auth.user) {
        this.getNotifications()
      }
    },
    toggleNotification() {
      // * method [requestNotification] is from notificationMixin
      this.requestNotification()
      this.showProfileDropdown = false
      this.showNotification = !this.showNotification
      if (this.hasNewNotification) {
        this.hasNewNotification = false
      }
    },
    onCloseNotification() {
      this.$nextTick().then(() => {
        if (this.showNotification) {
          this.showNotification = false
        }
      })
    },
    toggleProfileDropdown() {
      this.showNotification = false,
      this.showProfileDropdown = !this.showProfileDropdown
    },
    onCloseProfileDropdown() {
      this.$nextTick().then(() => {
        if (this.showProfileDropdown) {
          this.showProfileDropdown = false
        }
      })
    },
    handleSearch(e) {
      this.$router.push({
        path: '/search-results',
        query: {
          keyword: e.target.value,
        },
      })
      this.keywords = ''
    },
    setSelectedMenuKey(key) {
      this.selectedMenuKey = key
    },
    mouseOver() {
      this.hover = true
    },
    mouseLeave() {
      this.hover = false
    },
    handleLogin() {
      localStorage.setItem('loginRedirect', this.$route.fullPath)
      this.$router.push('/login')
    },
    handleSignUp() {
      if (this.$route.fullPath === '/') {
        this.$gtm.push({
          event: 'homepage_signup_initiation',
        })
        this.$gtm.push({
          event: 'sign_up_initiation_topnav',
        })
        this.$gtm.push({
          event: 'sign_up_initiation_landing_topnav',
        })
        localStorage.setItem('trackSignupFromHomepage', true)
      } else if (this.$route.name === 'nickname' && this.$route.params.nickname) {
        this.$gtm.push({
          event: 'profile_sign_up_initiation',
        })
        this.$gtm.push({
          event: 'sign_up_intiation_profile',
        })
      } else {
        this.$gtm.push({
          event: 'sign_up_initiation_topnav',
        })
      }
      localStorage.removeItem('loginRedirect')
      if (this.$route.fullPath !== '/') {
        localStorage.setItem('loginRedirect', this.$route.fullPath)
      }
      this.$router.push('/signup')
    },
    onClickTrackEvent(eventName) {
      if (!eventName) {
        console.warn('No event name provided for tracking.')
        return
      }
      const pushEvent = name => {
        this.$gtm.push({
          event: name,
          user_id: get(this.$auth, 'user.id') || '',
        })
      }

      if (typeof eventName === 'object') {
        eventName.forEach(pushEvent)
      } else {
        pushEvent(eventName)
      }
    },
  },
}
